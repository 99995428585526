/* eslint-disable import/no-duplicates */
import { useCallback } from 'react'
import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const useFormats = () => {
  const formatDateDefault = useCallback((date: Date): string => (
    format(date, 'dd/MM/yyyy', { locale: ptBR })
  ), [])

  const formatShortDateDefault = useCallback((date: Date): string => (
    format(date, 'dd/MM/yy')
  ), [])

  const formatHourDefault = (date: Date): string => (
    format(date, 'HH:mm')
  )

  const formatDateFilters = (date: Date): string => (
    date ? format(date, 'yyyy-MM-dd') : ''
  )

  const formatFilterToDate = (date: string): Date | string => (
    date ? parse(date, 'yyyy-MM-dd', new Date()) : ''
  )

  const formatDateTime = useCallback((date: Date): string => (
    format(date, 'dd/MM/yyyy - HH:mm')
  ), [])

  const formatMonthName = useCallback((date: Date): string => (
    format(date, 'MMMM', { locale: ptBR })
  ), [])

  const formatDay = useCallback((date: Date): string => (
    format(date, 'dd', { locale: ptBR })
  ), [])

  const formatCurrency = useCallback((value?: number) => (
    (value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  ), [])

  const formatCPF = useCallback((value: string) => (
    value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : ''
  ), [])

  const formatCNPJ = useCallback((value: string) => (
    value ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : ''
  ), [])

  const formatTelephone = useCallback((value: string) => {
    if (!value) return ''
    const phone = value.match(/(\d{2})(\d{5})(\d{4})/)

    if (!phone) return ''

    return `(${phone[1]}) ${phone[2]} - ${phone[3]}`
  }, [])

  const formatDateTimeZone = useCallback((date: string): Date | string => {
    if (date && date.length > 10) {
      const [year, month, day] = date.substr(0, 10).split('-')
      return format(new Date(
        +year,
        (+month - 1),
        +day,
      ), 'dd/MM/yyyy')
    }

    return ''
  }, [])

  const formatPostalCode = useCallback((postalCode: string): string => {
    if (!postalCode.length) {
      return ''
    }

    return `${postalCode.substr(0, 5)}-${postalCode.substr(5, postalCode.length)}`
  }, [])

  const formatOnlyNumbers = useCallback((value: string): string => (value ? value.replace(/[^\d]/g, '') : ''), [])

  const formatNumberStartWithZero = useCallback((value: number): string => (
    value < 10 ? `0${value}` : `${value}`
  ), [])

  return {
    formatDateDefault,
    formatShortDateDefault,
    formatHourDefault,
    formatDateFilters,
    formatFilterToDate,
    formatDateTime,
    formatDay,
    formatMonthName,
    formatCurrency,
    formatCPF,
    formatCNPJ,
    formatTelephone,
    formatDateTimeZone,
    formatPostalCode,
    formatOnlyNumbers,
    formatNumberStartWithZero,
  }
}
