import React from 'react'

import { Form, Formik } from 'formik'

import Label from '~/components/atoms/Label'
import Button from '~/components/atoms/Button'
import { P4 } from '~/components/atoms/Paragraph'
import FormControl from '~/components/molecules/FormControl'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import { CheckCircleIcon, CopyIcon } from '~/assets/icons'

import {
  BoletoGenerateButtonWrapper,
  CopyButtonWrapper,
  EmailFormControlWrapper,
  FakeInput,
  SendBoletoEmailRow,
  SendEmailButtonWrapper,
  OrWrapper,
} from '../PaymentMethods.styles'

import { colors, sizes } from '~/assets/styles/variables'

import { SendBoletoDataInterface } from '../models'
import { NewDischarge } from './NewDischarge'

import { useBoletoGeneration } from '../hooks'

const { size0, size24 } = sizes

const { gray, green } = colors

type Props = {
  digitableLine: string
  dealExternalCode: string
  initialValues: SendBoletoDataInterface
  type: 'view' | 'discharge'
}

export const BoletoGeneration = (props: Props) => {
  const { dealExternalCode, digitableLine, initialValues, type } = props

  const {
    validationSchema,
    isCopying,
    copyDigitableLine,
    generateBoleto,
    handleSendEmail,
  } = useBoletoGeneration({ dealExternalCode, digitableLine })

  return (
    <>
      <Label textAlign="left" style={{ marginTop: size24 }}>Copiar linha digitável do boleto</Label>

      <FakeInput>
        {digitableLine}

        <CopyButtonWrapper className={isCopying ? 'is-copying' : ''}>
          <Button
            onClick={copyDigitableLine}
            styledButton={ButtonTypeEnum.Icon}
            title="Copiar"
            type="button"
          >
            <CopyIcon />
          </Button>

          <CheckCircleIcon className="success-icon" color={green} />
        </CopyButtonWrapper>
      </FakeInput>

      <OrWrapper>ou</OrWrapper>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSendEmail}
        validateOnMount
        validationSchema={validationSchema}
      >
        {({ handleChange, isValid, values }) => (
          <Form autoComplete="off">
            <SendBoletoEmailRow>
              <EmailFormControlWrapper>
                <FormControl
                  marginBottom={size0}
                  name="email"
                  onChange={handleChange}
                  placeholder="Digite seu e-mail"
                  showError={false}
                  title="Enviar boleto por e-mail"
                  type="email"
                  value={values.email}
                />
              </EmailFormControlWrapper>

              <SendEmailButtonWrapper>
                <Button type="submit" disabled={!isValid} displayBlock size="large">
                  Enviar e-mail
                </Button>
              </SendEmailButtonWrapper>
            </SendBoletoEmailRow>
          </Form>
        )}
      </Formik>

      <OrWrapper>ou</OrWrapper>

      <BoletoGenerateButtonWrapper>
        <Button type="button" displayBlock size="large" onClick={generateBoleto}>
          Gerar boleto em PDF
        </Button>
      </BoletoGenerateButtonWrapper>

      <P4 color={gray}>
        Para visualizar o boleto protegido por senha, é necessário informar os 4 primeiros dígitos
        de seu CPF.
      </P4>

      {type === 'discharge' && <NewDischarge />}
    </>
  )
}
