import { useHistory } from 'react-router-dom'

import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'

export type NewDischargeType = {
    handleRedirect: (page: string) => void
}

export const useNewDischarge = () => {
  const history = useHistory()
  const { regEventGa } = useGoogleAnalytics()

  const handleRedirect = (page: string) => {
    if (page === '/quitacao') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
        GoogleAnalyticsEventTypeEnum.QuitacaoFazerNovaQuitacao
      )
    }

    if (page === '/menu-cliente') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
        GoogleAnalyticsEventTypeEnum.QuitacaoEncerrarQuitacao
      )
    }

    history.push(page)
  }

  const boleto: NewDischargeType = {
    handleRedirect,
  }

  return boleto
}
