import React from 'react'

import { Flex } from '~/components/atoms/Flex'
import Button from '~/components/atoms/Button'
import { P1, P2 } from '~/components/atoms/Paragraph'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'

import { RedirectButtonsRow } from '../PaymentMethods.styles'

import { colors } from '~/assets/styles/variables'
import { useNewDischarge } from '../hooks'

export const NewDischarge = () => {
  const { handleRedirect } = useNewDischarge()

  return (
    <Flex
      margin="16px 0 0 0"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        borderRadius: '12px',
        backgroundColor: colors.white,
        padding: '24px',
        textAlign: 'center',
      }}
    >
      <P1
        margin="0"
        color={colors.textBlack}
        style={{ fontSize: '29.03px', lineHeight: '40px', fontWeight: 700 }}
      >
        Fazer nova quitação?
      </P1>

      <P2
        margin="16px 0 26px 0"
        color={colors.darkGray}
        style={{ fontSize: '16.8px', lineHeight: '24px' }}
      >
        Você ainda tem um ou mais contratos que podem ser quitados ou adiantados.
      </P2>

      <Flex justifyContent="center" style={{ width: '100%' }}>
        <RedirectButtonsRow>
          <Button
            type="button"
            size="small"
            styledButton={ButtonTypeEnum.Outline}
            onClick={() => handleRedirect('/menu-cliente')}
          >
            Não, encerrar quitação
          </Button>

          <Button type="button" size="small" onClick={() => handleRedirect('/quitacao')}>
            Sim, fazer nova Quitação
          </Button>
        </RedirectButtonsRow>
      </Flex>
    </Flex>
  )
}
