import React from 'react'

import { Flex } from '~/components/atoms/Flex'
import { P1, P2, P3 } from '~/components/atoms/Paragraph'
import { CopyButton } from '~/components/atoms/CopyButton'

import { PendingInstallmentInterface } from '~/models/interfaces/services/Deals'

import { FlexBox, InstructionsWrapper, Ol, QrCodeWrapper } from '../PaymentMethods.styles'

import { colors } from '~/assets/styles/variables'
import { NewDischarge } from './NewDischarge'

type Props = {
  dataPix: PendingInstallmentInterface['pixs'][0] | undefined
  qrCode: string
  type: 'view' | 'discharge'
}

export const PixGeneration = (props: Props) => {
  const { dataPix, qrCode, type } = props

  return (
    <>
      <FlexBox
        style={{
          justifyContent: type === 'discharge' ? 'flex-start' : 'center',
          marginBottom: type === 'view' ? '16px' : 'none',
        }}
      >
        <QrCodeWrapper>
          <P2 margin="0 0 16px 0" color={colors.textBlack} style={{ lineHeight: '22.4px' }}>
            {`Efetue o pagamento até o dia ${new Date(
              dataPix?.dueDate as string
            ).toLocaleDateString('pt-BR')}`}
          </P2>

          <img src={`data:image/jpeg;base64,${qrCode}`} alt="QR Code" title="QR Code" width={200} />

          <P1
            margin="8px 0 16px 0"
            color={colors.textBlack}
            style={{ fontSize: '24px', lineHeight: '33.6px' }}
          >
            {'Valor: '}
            <strong>
              {dataPix?.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </strong>
          </P1>

          <Flex
            margin="0 0 16px 0"
            style={{
              width: '100%',
              padding: '16px',
              backgroundColor: colors.white,
              borderRadius: '8px',
            }}
          >
            <P3
              className="copyQrCodeData"
              margin={0}
              color={colors.blue}
              style={{
                lineHeight: '21px',
              }}
            >
              <strong>{dataPix?.qrCodeData}</strong>
            </P3>
          </Flex>

          <CopyButton value={dataPix?.qrCodeData} style={{ width: '100%' }}>
            Copiar código pix
          </CopyButton>
        </QrCodeWrapper>

        {type === 'discharge' && (
          <InstructionsWrapper>
            <P2 margin="0" color={colors.textBlack} style={{ lineHeight: '22.4px' }}>
              <strong>Instruções para fazer o pagamento por Pix:</strong>
            </P2>

            <Ol>
              <li>
                <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                  Abra o aplicativo do seu banco no celular.
                </P2>
              </li>
              <li>
                <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                  Procure a opção de pagamentos ou transferências.
                </P2>
              </li>
              <li>
                <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                  Escolha a forma de pagamento Pix e selecione &quot;QR Code&quot;.
                </P2>
              </li>
              <li>
                <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                  Agora, aponte a câmera do seu celular para o QR Code, ou clique em &quot;Copiar
                  código&quot; e cole-o no aplicativo do banco.
                </P2>
              </li>
              <li>
                <P2 margin="0" style={{ lineHeight: '22.4px' }}>
                  Verifique se o valor está correto e confirme o pagamento.
                </P2>
              </li>
            </Ol>
          </InstructionsWrapper>
        )}
      </FlexBox>

      {type === 'discharge' && <NewDischarge />}
    </>
  )
}
