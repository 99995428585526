import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Wizard from '~/components/molecules/Wizard'
import PageLoader from '~/components/organisms/PageLoader'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { WizardInputInterface } from '~/models/interfaces/components/Wizard'
import { DealOutputInterface } from '~/models/interfaces/services/Customers'

import store from '~/store'
import { RootState } from '~/store/reducers'

import { StateInterface } from './models'

import PaymentMethods from '~/components/payment/PaymentMethods'

const PaymentGeneration = () => {
  const { state }: { state: StateInterface } = useLocation()

  const authSelector = useSelector(({ auth }: RootState) => auth)
  const { user } = authSelector

  const [loading, setLoading] = useState(true)
  const [deal, setDeal] = useState<DealOutputInterface>()

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
    },
    {
      title: 'Efetivação',
    },
    {
      title: 'Geração de pagamento',
      active: true,
    },
  ]

  useEffect(() => {
    if (!user || !state || !state.deal || deal) return

    store.dispatch({
      type: 'SET_INITIAL_DISCHARGE_CONTRACTS',
    })

    setDeal(state.deal)

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [deal, state, user])

  return (
    <PortalTemplate>
      {loading && <PageLoader />}

      <Wizard>{wizardElements}</Wizard>

      {deal && (
        <PaymentMethods
          type="discharge"
          dealExternalCode={deal.externalCode}
          installmentExternalCode={deal.installmentExternalCode}
          paymentType={deal.paymentTypes.type}
        />
      )}
    </PortalTemplate>
  )
}

export default PaymentGeneration
