import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'

import { Hidden, Visible } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { useSelector } from 'react-redux'
import { colors, sizes } from '~/assets/styles/variables'

import { CampaignBanner } from '~/components/atoms/Banner/common'
import Button from '~/components/atoms/Button'
import { Card } from '~/components/atoms/Card'
import { H4, H5 } from '~/components/atoms/Heading'
import PageContainer from '~/components/atoms/PageContainer'
import {
  P1, P1Bold, P3, P4,
} from '~/components/atoms/Paragraph'
import Tag from '~/components/atoms/Tag'
import FormControl from '~/components/molecules/FormControl'
import ActionBar from '~/components/molecules/ActionBar'
import Wizard from '~/components/molecules/Wizard'
import PortalTemplate from '~/components/templates/PortalTemplate'

import { pageWidth } from '~/config/layout/pageWidth'
import setTitle from '~/config/title'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import { SelectOptionsInterface } from '~/models/interfaces/components/FormControl'
import { WizardInputInterface } from '~/models/interfaces/components/Wizard'

import {
  DealSimulationCalculationOutputInterface,
} from '~/models/interfaces/services/Customers'

import {
  effectuationDischargeByCustomerAndInstallment,
  getDischargeSimulationByCustomerAndInstallment,
  getDischargeSimulationByCustomerAndInstallmentCalculation,
} from '~/services/Customers'

import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useFormats } from '~/utils/useFormats'

import Icon from '~/assets/icons'
import {
  CalculateButtonWrapper,
  ChangeSelectedItemsContainer,
  DiscountValueCardBodyStyled,
  DiscountCalculateRow,
  DueDateFormControlWrapper,
  H2Styled,
  TotalSelectedCardBodyStyled,
  DiscountValueCardStyled,
  PaymentMethodContainer,
  LabelContainer,
} from './Effectuation.styles'

const {
  size0, size8, size16, size24, size64,
} = sizes

const { darkGray, gray } = colors

interface CalculateDiscountDataInterface {
  dueDate: string
  id: string
  installmentIds: string[]
  issueDate: string
}

interface EffectuateDataInterface {
  dueDate: string
  id: string
  installmentIds: string[]
  issueDate: string
}

const validationSchemaToCalculateDiscount: yup.SchemaOf<CalculateDiscountDataInterface> = yup.object({
  dueDate: yup.string().required(''),
  id: yup.string().required(''),
  installmentIds: yup.array().min(1, ''),
  issueDate: yup.string().required(''),
})

const validationSchemaToEffectuate: yup.SchemaOf<EffectuateDataInterface> = yup.object({
  dueDate: yup.string().required(''),
  id: yup.string().required(''),
  installmentIds: yup.array().min(1, ''),
  issueDate: yup.string().required(''),
})

const Effectuation = () => {
  setTitle('Efetivação - Quitação')

  const wizardElements: WizardInputInterface[] = [
    {
      title: 'Seleção de contratos',
    },
    {
      title: 'Efetivação',
      active: true,
    },
    {
      title: 'Geração de boletos',
    },
  ]

  const containerPageWidth = pageWidth.medium

  const { formatCurrency, formatDateDefault, formatDateFilters } = useFormats()
  const { regEventGa } = useGoogleAnalytics()
  const history = useHistory()

  const [authSelector, userDataSelector, dischargeContractsSelector] = useSelector(
    ({
      auth, userDataProcessing, dischargeContracts,
    }: RootState) => [auth, userDataProcessing, dischargeContracts] as const,
  )

  const { user } = authSelector

  const { contracts } = dischargeContractsSelector

  const { idEntryAutomation } = userDataSelector

  const [blockCalculation,
    setBlockCalculation] = useState<boolean>(false)

  const [blockEffectuation,
    setBlockEffectuation] = useState<boolean>(true)

  const [dealSimulationCalculationOutput,
    setDealSimulationCalculationOutput] = useState<DealSimulationCalculationOutputInterface | null>(null)

  const [dueDateRange,
    setDueDateRange] = useState<SelectOptionsInterface[]>([])

  const [totalValue,
    setTotalValue] = useState<number>(0)

  const [valueToPay,
    setValueToPay] = useState<number>(0)

  const [crmEventDescription,
    setCrmEventDescription] = useState<string>('')

  const [paymentMethods, setPaymentMethods] = useState<DealSimulationCalculationOutputInterface['paymentType']>()
  const [paymentMethod, setPaymentMethod] = useState<string>('')

  const [initialValuesToCalculateDiscount,
    setInitialValuesToCalculateDiscount] = useState<CalculateDiscountDataInterface>({
      dueDate: '',
      id: '',
      installmentIds: [],
      issueDate: '',
    })

  const [initialValuesToEffectuate,
    setInitialValuesToEffectuate] = useState<EffectuateDataInterface>({
      dueDate: '',
      id: '',
      installmentIds: [],
      issueDate: '',
    })

  useEffect(() => {
    if (!contracts.length) {
      history.push('/quitacao')
    }
  }, [
    contracts.length,
    history])

  useEffect(() => {
    if (!user
       || initialValuesToCalculateDiscount.id !== ''
    ) return

    setInitialValuesToCalculateDiscount({
      ...initialValuesToCalculateDiscount,
      id: user.id,
    })
  }, [initialValuesToCalculateDiscount, user])

  const convertDueDateToSelectOption = useCallback((dueDate: Date): SelectOptionsInterface => (
    {
      label: formatDateDefault(new Date(dueDate)),
      value: formatDateFilters(new Date(dueDate)),
    }
  ), [formatDateDefault, formatDateFilters])

  useEffect(() => {
    if (!user
      || dealSimulationCalculationOutput
      || initialValuesToCalculateDiscount.installmentIds.length
      || initialValuesToCalculateDiscount.issueDate !== ''
    ) return

    const selectedInstallmentIds: string[] = []

    const selectedContracts: string[] = []

    contracts.forEach((contract) => {
      let hasSelectedInstallments = false
      const installmentNumbers: number[] = []

      contract.installments.forEach((installment) => {
        if (installment.selected) {
          hasSelectedInstallments = true

          selectedInstallmentIds.push(installment.externalCode)
          installmentNumbers.push(installment.installmentNumber)
        }
      })

      if (hasSelectedInstallments) {
        const selectedContract = `Produto: ${contract.productName}, `
                               + `Contrato: ${contract.contractNumber}, `
                               + `Parcelas: ${installmentNumbers.join(', ')}`

        selectedContracts.push(selectedContract)
      }
    })

    if (crmEventDescription === '' && selectedInstallmentIds.length) {
      const newCrmEventDescription = selectedContracts.join('\n')

      setCrmEventDescription(newCrmEventDescription)

      getDischargeSimulationByCustomerAndInstallment(
        user.id,
        selectedInstallmentIds,
        user.cpfCnpj,
        newCrmEventDescription,
        idEntryAutomation,
      )
        .then((response) => {
          setDealSimulationCalculationOutput(response)

          const newDueDateRange: SelectOptionsInterface[] = []

          response.dueRangeDates.forEach((dueDate) => {
            newDueDateRange.push(convertDueDateToSelectOption(dueDate))
          })

          setDueDateRange(newDueDateRange)

          const values = {
            ...initialValuesToCalculateDiscount,
            installmentIds: selectedInstallmentIds,
            issueDate: formatDateFilters(new Date(response.parcelings[0].issueDate)),
          }

          setInitialValuesToCalculateDiscount(values)
        })
    }
  }, [contracts,
    convertDueDateToSelectOption,
    dealSimulationCalculationOutput,
    formatDateFilters,
    initialValuesToCalculateDiscount,
    user,
    crmEventDescription,
    idEntryAutomation])

  const handleChangeDueDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setInitialValuesToCalculateDiscount({
      ...initialValuesToCalculateDiscount,
      dueDate: event.target.value,
    })

    setBlockCalculation(false)
    setBlockEffectuation(true)
  }

  const simulationResultRef = useRef<HTMLDivElement>(null)

  const calcuteDiscount = (values: CalculateDiscountDataInterface) => {
    if (!dealSimulationCalculationOutput
      || !user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      GoogleAnalyticsEventTypeEnum.QuitacaoCalcularDesconto,
    )

    let { dueDate } = values
    const { id } = values
    let { installmentIds } = values
    let { issueDate } = values

    const dueDateLabel = dueDateRange.find((d) => d.value === dueDate)?.label
    const newDueDateRange = [...dueDateRange]
    const minDueDate = newDueDateRange.shift()
    const maxDueDate = newDueDateRange.pop()

    const eventDescription = `${crmEventDescription}\n`
                           + `Data mínima: ${minDueDate?.label}, `
                           + `Data máxima: ${maxDueDate?.label}, `
                           + `Data selecionada: ${dueDateLabel}`

    getDischargeSimulationByCustomerAndInstallmentCalculation(
      dueDate,
      id,
      installmentIds,
      issueDate,
      user.cpfCnpj,
      eventDescription,
      idEntryAutomation,
    )
      .then((response) => {
        setBlockEffectuation(false)

        setTimeout(() => {
          if (simulationResultRef.current) {
            window.scrollTo({
              behavior: 'smooth',
              top: simulationResultRef.current.offsetTop,
            })
          }
        }, 0)

        dueDate = formatDateFilters(new Date(response.parcelings[0].dueDate))
        installmentIds = response.installments.map((installment) => (
          installment.externalCode))
        issueDate = formatDateFilters(new Date(response.parcelings[0].issueDate))

        setInitialValuesToEffectuate({
          ...initialValuesToCalculateDiscount,
          dueDate,
          id,
          installmentIds,
          issueDate,
        })

        setBlockCalculation(true)
        setTotalValue(response.valueTotal)
        setValueToPay(response.parcelings[0].valueToPay)
        setPaymentMethods(response.paymentType)

        const pixPaymentMethod = response.paymentType.find((payment) => payment.type === 'P')
        if (pixPaymentMethod) setPaymentMethod(pixPaymentMethod.id)
        else setPaymentMethod(response.paymentType[0].id)
      })
  }

  const effectuate = (values: EffectuateDataInterface) => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      GoogleAnalyticsEventTypeEnum.QuitacaoEfetivar,
    )

    const { dueDate, id, installmentIds, issueDate } = values

    effectuationDischargeByCustomerAndInstallment({
      dueDate,
      id,
      installmentExternalCodes: installmentIds,
      issueDate,
      idEntryAutomation,
      paymentTypeId: paymentMethod,
    })
      .then((response) =>
        history.push('/quitacao/geracao-pagamento', {
          deal: response,
        }))
      .catch(() => history.push('/quitacao/erro'))
  }

  const handleGoBack = (gaEventType: GoogleAnalyticsEventTypeEnum) => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      gaEventType,
    )

    history.goBack()
  }

  return (
    <PortalTemplate>
      { !!dealSimulationCalculationOutput && (
      <>
        <Wizard>{wizardElements}</Wizard>

        <PageContainer width={containerPageWidth}>

          <CampaignBanner />

          <P1Bold marginBottom={size24}>
            Total selecionado:
          </P1Bold>

          <Card marginBottom={size64} negative>
            <TotalSelectedCardBodyStyled>
              <H5 color={darkGray} marginBottom={size0}>
                {formatCurrency(dealSimulationCalculationOutput.valueTotal)}
              </H5>
              <Button
                onClick={() => handleGoBack(
                  GoogleAnalyticsEventTypeEnum.QuitacaoRevisar,
                )}
                size="small"
                styledButton={ButtonTypeEnum.Outline}
                type="button"
              >
                Revisar
              </Button>
            </TotalSelectedCardBodyStyled>
          </Card>

          <H4 marginBottom={size24}>
            Escolha a data de vencimento do boleto para calcularmos o valor final com desconto:
          </H4>

          <Formik
            enableReinitialize
            initialValues={initialValuesToCalculateDiscount}
            onSubmit={calcuteDiscount}
            validateOnMount
            validationSchema={validationSchemaToCalculateDiscount}
          >
            {({
              isValid, values,
            }) => (
              <Form autoComplete="off">
                <DiscountCalculateRow>
                  <DueDateFormControlWrapper>
                    <FormControl
                      marginBottom={size0}
                      name="dueDate"
                      onChange={(event) => handleChangeDueDate(event)}
                      placeholder="Escolha a data"
                      showError={false}
                      title="Selecione o vencimento"
                      type="select"
                      value={values.dueDate}
                      valuesSelect={dueDateRange}
                    />
                  </DueDateFormControlWrapper>
                  <Visible xs>
                    <P4 color={gray} marginBottom={size16}>
                      A data escolhida afeta os valores dos descontos
                    </P4>
                  </Visible>
                  <CalculateButtonWrapper>
                    <Button
                      disabled={!isValid || blockCalculation}
                      displayBlock
                      size="large"
                      type="submit"
                    >
                      Calcular desconto
                    </Button>
                  </CalculateButtonWrapper>
                </DiscountCalculateRow>
                <Hidden xs>
                  <P4 color={gray} marginBottom={size0}>
                    A data escolhida afeta os valores dos descontos
                  </P4>
                </Hidden>
              </Form>
            )}
          </Formik>

          {!blockEffectuation && (
          <>
            <DiscountValueCardStyled marginBottom={size64} ref={simulationResultRef} negative>
              <DiscountValueCardBodyStyled>
                {dealSimulationCalculationOutput && (
                <>
                  {totalValue > (valueToPay + 0.01) && (
                  <P1
                    className="original-value"
                    color={gray}
                    marginBottom={size0}
                  >
                    {formatCurrency(totalValue)}
                  </P1>
                  )}

                  <H2Styled>
                    {formatCurrency(valueToPay)}
                  </H2Styled>

                  <Tag>
                    Pagamento somente à vista
                  </Tag>
                </>
                )}
              </DiscountValueCardBodyStyled>
            </DiscountValueCardStyled>

            {!!valueToPay && (
              <PaymentMethodContainer>
                <FormControl
                  name="paymentMethod"
                  type="radio"
                  value={paymentMethod}
                  valuesRadio={paymentMethods?.map((payment) => {
                    const icon = {
                      BP: 'Pix',
                      B: 'BarCode',
                    }[payment.type]
                    const [paymentType, paymentBank] = payment.name.split(' - ')

                    return {
                      id: payment.id,
                      label: (
                        <LabelContainer>
                          {!!icon && <Icon type={icon} color={colors.blue} />}
                          <div>
                            <P1 marginBottom="0">{paymentType}</P1>
                            <P4 marginBottom="0">{paymentBank}</P4>
                          </div>
                        </LabelContainer>
                      ),
                    }
                  })}
                  marginBottom={size0}
                  marginBottomItems="0"
                  textAlign="left"
                  onChange={(event) => setPaymentMethod(event.target.value)}
                  showError={false}
                  spanAlign
                />
              </PaymentMethodContainer>
            )}

            <ChangeSelectedItemsContainer>
              <P3 color={darkGray} marginBottom={size8}>
                Não está satisfeito(a)?
              </P3>
              <Button
                onClick={() => handleGoBack(
                  GoogleAnalyticsEventTypeEnum.QuitacaoAlterarItensSelecionados,
                )}
                size="small"
                styledButton={ButtonTypeEnum.Outline}
                type="submit"
              >
                Alterar itens selecionados
              </Button>
            </ChangeSelectedItemsContainer>
          </>
          )}

          <Formik
            enableReinitialize
            initialValues={initialValuesToEffectuate}
            onSubmit={effectuate}
            validateOnMount
            validationSchema={validationSchemaToEffectuate}
          >
            {({ isValid }) => (
              <Form autoComplete="off">
                <ActionBar
                  nextButtonText="Efetivar"
                  submitActive={!blockEffectuation && isValid}
                  width={containerPageWidth}
                />
              </Form>
            )}
          </Formik>

        </PageContainer>
      </>
      )}
    </PortalTemplate>
  )
}

export default Effectuation
