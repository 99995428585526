import styled from 'styled-components'

import { breakpoint, device } from '~/config/device'
import { colors, sizes, zIndex } from '~/assets/styles/variables'

const {
  size0, size16, size24, size32, size48,
} = sizes

const { gray, white } = colors
const { firstLayer, secondLayer } = zIndex

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  transition: 0.5s;

  @media (max-width: ${breakpoint.md}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const FlexBoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  transition: 0.5s;
`

export const QrCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 21.25px 0 0 0;
  border-radius: 12px;
  transition: 0.5s;

  .copyQrCodeData {
    line-break: anywhere;
  }

  @media ${device.sm} {
    width: 407px;

    .copyQrCodeData {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 359px;
  background-color: ${colors.white};
  border-radius: 12px;
`

export const Ol = styled.ol`
  margin: 0;
  padding: 0px 0px 0px 22px;

  li {
    color: ${colors.textBlack};
    margin: 16px 0 0 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: -2rem 0 3rem 0;

  svg {
    min-width: 33px;
  }

  p {
    font-size: 29.03px;
  }

  @media (max-width: ${breakpoint.md}px) {
    text-align: left;

    p {
      font-size: 24px;
    }
  }
`

export const RedirectButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${size16};
  justify-content: space-between;

  @media ${device.xs} {
    flex-direction: column;
  }
`

export const FakeInput = styled.div`
  background-color: ${white};
  border: 2px solid ${gray};
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: ${size32};
  padding: ${size16};
  padding-right: ${size48};
  position: relative;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
`

export const CopyButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: ${size24};

  button {
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s;
    z-index: ${secondLayer};
  }

  .success-icon {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s;
    z-index: ${firstLayer}
  }

  &.is-copying {
    .success-icon {
      opacity: 1;
    }


    button {
      opacity: 0;
    }
  }
`

export const SendBoletoEmailRow = styled.div`
  margin-bottom: ${size32};

  @media ${device.sm} {
    align-items: flex-end;
    display: flex;
  }
`

export const EmailFormControlWrapper = styled.div`
  margin-bottom: ${size16};

  @media ${device.sm} {
    flex: 1;
    margin-bottom: ${size0};
  }
`

export const SendEmailButtonWrapper = styled.div`
  @media ${device.sm} {
    margin-left: ${size16};
  }
`

export const OrWrapper = styled.div`
  margin-bottom: ${size32};
  display: flex;
  justify-content: center;
`

export const BoletoGenerateButtonWrapper = styled.div`
  margin-bottom: ${size16};
`
