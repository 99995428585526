/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { pixService } from '~/services/Pix'
import { getDataBoleto, getDataPix } from '~/services/Deals'

import Button from '~/components/atoms/Button'
import { CheckCircleIcon } from '~/assets/icons'
import { P1 } from '~/components/atoms/Paragraph'
import PageContainer from '~/components/atoms/PageContainer'

import { BoletoGeneration, PixGeneration } from './components'
import { FlexBoxButtons, TitleWrapper } from './PaymentMethods.styles'

import ButtonTypeEnum from '~/models/enums/ButtonTypeEnum'
import { PaymentOptionsInterface, SendBoletoDataInterface } from './models'
import { PendingInstallmentInterface } from '~/models/interfaces/services/Deals'

import { RootState } from '~/store/reducers'
import { colors } from '~/assets/styles/variables'
import { pageWidth } from '~/config/layout/pageWidth'

type Props = {
  dealExternalCode: string
  installmentExternalCode: string
  paymentType: string
  type: 'view' | 'discharge'
}

const PaymentMethods = (props: Props) => {
  const { dealExternalCode, installmentExternalCode, paymentType, type } = props

  const authSelector = useSelector(({ auth }: RootState) => auth)

  const customerId = JSON.parse(localStorage.getItem('__viacertaNegotiationPortalUser__') || '').id

  const { user } = authSelector

  const [qrCode, setQrCode] = useState<string>('')
  const [dataPix, setDataPix] = useState<PendingInstallmentInterface['pixs'][0]>()
  const [digitableLine, setDigitableLine] = useState<string>('')
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsInterface[]>([])
  const [initialValues, setInitialValues] = useState<SendBoletoDataInterface>({ email: '' })

  const handleChangePaymentOption = (option: 'pix' | 'boleto') => {
    setPaymentOptions((prevPaymentOptions) =>
      prevPaymentOptions.map((item) => ({
        ...item,
        isActive: item.id === option,
      })))
  }

  useEffect(() => {
    const fetchData = async () => {
      const options: PaymentOptionsInterface[] = []

      try {
        const responsePix = await getDataPix(customerId)
        const pix = responsePix.filter((elem) => elem.externalCode === dealExternalCode)[0]
          .installments[0].pixs[0]

        const responseQrCode = await pixService.getQRCode(pix.id)
        setDataPix(pix)
        setQrCode(responseQrCode)

        options.push({
          id: 'pix',
          label: 'Pix',
          isActive: true,
        })
      } catch (error) {}

      if (paymentType !== 'BP') {
        try {
          const responseBoleto = await getDataBoleto(installmentExternalCode)
          setDigitableLine(responseBoleto.digitableLine)

          options.push({
            id: 'boleto',
            label: 'Boleto',
            isActive: false,
          })
        } catch (error) {}
      }

      setPaymentOptions(options)
    }

    setTimeout(() => {
      fetchData()
    }, type === 'discharge' ? 2000 : 0)
  }, [customerId, dealExternalCode, installmentExternalCode, paymentType, type])

  useEffect(() => {
    if (!user) return

    setInitialValues({ email: user.email })
  }, [user])

  return (
    <>
      {paymentOptions.length > 0 && (
        <>
          <PageContainer width={pageWidth.medium}>
            {type === 'discharge' && (
              <TitleWrapper>
                <CheckCircleIcon color={colors.green} size={33} style={{ margin: '0 16px 0 0' }} />

                <P1 margin="0" color={colors.blue} style={{ lineHeight: '40px', fontWeight: 700 }}>
                  Negociação gerada com sucesso!
                </P1>
              </TitleWrapper>
            )}

            {paymentOptions.length === 1 ? (
              <>
                {paymentOptions[0].id === 'pix' && (
                  <PixGeneration dataPix={dataPix} qrCode={qrCode} type={type} />
                )}

                {paymentOptions[0].id === 'boleto' && (
                  <BoletoGeneration
                    dealExternalCode={dealExternalCode}
                    digitableLine={digitableLine}
                    initialValues={initialValues}
                    type={type}
                  />
                )}
              </>
            ) : (
              <>
                <FlexBoxButtons
                  style={{
                    marginTop: type === 'view' ? '16px' : 'none',
                  }}
                >
                  {paymentOptions.map((option) => (
                    <Button
                      key={option.id}
                      type="button"
                      size="medium"
                      onClick={() => handleChangePaymentOption(option.id)}
                      styledButton={
                        option.isActive ? ButtonTypeEnum.Default : ButtonTypeEnum.Outline
                      }
                    >
                      {option.label}
                    </Button>
                  ))}
                </FlexBoxButtons>

                {paymentOptions.find((option) => option.id === 'pix' && option.isActive) && (
                  <PixGeneration dataPix={dataPix} qrCode={qrCode} type={type} />
                )}

                {paymentOptions.find((option) => option.id === 'boleto' && option.isActive) && (
                  <BoletoGeneration
                    dealExternalCode={dealExternalCode}
                    digitableLine={digitableLine}
                    initialValues={initialValues}
                    type={type}
                  />
                )}
              </>
            )}
          </PageContainer>
        </>
      )}
    </>
  )
}

export default PaymentMethods
