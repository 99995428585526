import React from 'react'

import { DealPendingsInterface } from '~/models/interfaces/services/Deals'

import PaymentMethods from '~/components/payment/PaymentMethods'

type FormModalViewPixProps = {
  formData: {
    values: DealPendingsInterface
  }
}

export const FormModalPayment = (props: FormModalViewPixProps) => {
  const { formData } = props
  const deal = formData.values

  return (
    <PaymentMethods
      type="view"
      dealExternalCode={deal.externalCode}
      installmentExternalCode={deal.installments[0].externalCode}
      paymentType={deal.paymentMethod.type}
    />
  )
}

export default FormModalPayment
