import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import SendEmailTypeEnum from '~/models/enums/SendEmailTypeEnum'
import InstallmentTypeEnum from '~/models/enums/InstallmentTypeEnum'
import GoogleAnalyticsEventTypeEnum from '~/models/enums/GoogleAnalyticsEventTypeEnum'
import NegotiationPortalFlowTypeEnum from '~/models/enums/NegotiationPortalFlowTypeEnum'
import GoogleAnalyticsEventCategoryTypeEnum from '~/models/enums/GoogleAnalyticsEventCategoryTypeEnum'

import { getBoletosFile } from '~/services/Deals'
import { sendBoletoEmail } from '~/services/SendBoletoEmail'
import SendBoletoEmailInterface, { InstallmentsSendBoletoEmailInterface } from '~/models/interfaces/services/SendBoletoEmail'

import { SendBoletoDataInterface } from '../models'

import { RootState } from '~/store/reducers'

import { useGoogleAnalytics } from '~/utils/useGoogleAnalytics'
import { useShowModalSendingMail } from '~/utils/useShowModalSendingMail'

export type BoletoGenerationType = {
  isCopying: boolean
  validationSchema: yup.SchemaOf<SendBoletoDataInterface>
  copyDigitableLine: () => void
  generateBoleto: () => void
  handleSendEmail: (values: SendBoletoDataInterface) => void
  handleRedirect: (page: string) => void
}

type Props = {
  digitableLine: string
  dealExternalCode: string
}

export const useBoletoGeneration = (props: Props) => {
  const { digitableLine, dealExternalCode } = props
  const history = useHistory()
  const { regEventGa } = useGoogleAnalytics()
  const { mailError, mailSuccess } = useShowModalSendingMail()

  const authSelector = useSelector(({ auth }: RootState) => auth)
  const userDataSelector = useSelector(({ userDataProcessing }: RootState) => userDataProcessing)

  const { user } = authSelector
  const { idEntryAutomation } = userDataSelector

  const [isCopying, setIsCopying] = useState<boolean>(false)

  const validationSchema: yup.SchemaOf<SendBoletoDataInterface> = yup.object({
    email: yup.string().email('').required(''),
  })

  const handleRedirect = (page: string) => {
    if (page === '/quitacao') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
        GoogleAnalyticsEventTypeEnum.QuitacaoFazerNovaQuitacao
      )
    }

    if (page === '/menu-cliente') {
      regEventGa(
        GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
        GoogleAnalyticsEventTypeEnum.QuitacaoEncerrarQuitacao
      )
    }

    history.push(page)
  }

  const copyDigitableLine = () => {
    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      GoogleAnalyticsEventTypeEnum.QuitacaoCopiarLinhaDigitavel
    )

    navigator.clipboard.writeText(digitableLine)

    setTimeout(() => {
      setIsCopying(true)
    }, 250)

    setTimeout(() => {
      setIsCopying(false)
    }, 3500)
  }

  const handleSendEmail = (values: SendBoletoDataInterface) => {
    if (!user || !dealExternalCode) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      GoogleAnalyticsEventTypeEnum.QuitacaoEnvioEmail
    )

    const installmentsSendBoletoEmail: InstallmentsSendBoletoEmailInterface[] = [
      {
        dealId: dealExternalCode,
        installmentType: InstallmentTypeEnum.Deal,
      },
    ]

    const stateSendBoletoEmail: SendBoletoEmailInterface = {
      customerId: user.id,
      installments: installmentsSendBoletoEmail,
      to: values.email,
    }

    const eventDescription = `E-mail: ${values.email}`

    sendBoletoEmail(
      SendEmailTypeEnum.BoletoPagamentoAvulso,
      stateSendBoletoEmail,
      user.cpfCnpj,
      eventDescription,
      NegotiationPortalFlowTypeEnum.Quitacao,
      idEntryAutomation || undefined
    )
      .then(mailSuccess)
      .catch(mailError)
  }

  const generateBoleto = () => {
    if (!dealExternalCode || !user) return

    regEventGa(
      GoogleAnalyticsEventCategoryTypeEnum.Quitacao,
      GoogleAnalyticsEventTypeEnum.QuitacaoImprimirPdf
    )

    const negotiationPortalFlowTypeEnum = NegotiationPortalFlowTypeEnum.Quitacao

    getBoletosFile(
      dealExternalCode,
      user.cpfCnpj,
      negotiationPortalFlowTypeEnum,
      idEntryAutomation || undefined
    ).then((data) => {
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    })
  }

  const boleto: BoletoGenerationType = {
    validationSchema,
    isCopying,

    copyDigitableLine,
    generateBoleto,
    handleSendEmail,
    handleRedirect,
  }

  return boleto
}
